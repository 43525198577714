<template>
  <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
  <div class="row justify-content-center">
      <div class="col-12">
        <div class="row" v-if="!isLoad">
          <div class="col-12">
            <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create New Role</h1>
            <hr>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                  <form @submit.prevent="patchRole" class="row p-2">
                      <div class="col-lg-12 form-group mb-3">
                          <label for="">Role Name</label>
                          <input v-model="role.name" type="text" required class="form-control text-grey-900 font-xsss fw-600">                        
                      </div>
                      <div class="col-xl-12 form-group text-right my-4">
                          <router-link to="/admin/privillage" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                          <input type="Submit" class="btn btn-current" value="Submit">
                      </div>
                  </form>
                </div>
            </div>
          </div>
          <div class="col-12"></div>
        </div>
        <div class="row" v-else>
          <div class="col-12 text-center">
            <div class="my-3">
              <div class="spinner-grow text-warning mx-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-warning mx-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-warning mx-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-warning mx-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-warning mx-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-warning mx-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
</template>

<script>
  import axios from 'axios'
  export default {
    data(){
      return {
        idRole: this.$route.params.idRole,
        role: null,
        isLoad: true,
        validationErrors: ''
      }
    },
    created(){
      this.getRole()
    },
    methods: {
      async getRole(){
        await axios.get(`${process.env.VUE_APP_URL_API}/admin/role/detail?id=${this.idRole}`, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`
          }
        }).then(res => {
          this.role = res.data
          this.isLoad = false
        }).catch((err) => console.error(err))
      },
      async patchRole(){
        var data = {
          _method: 'patch',
          id: this.role.id,
          name: this.role.name,
        }
        await axios.post(`${process.env.VUE_APP_URL_API}/admin/role`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
          },
        }).then(res => {
          this.$swal({
            toast: true,
            title: 'Success Update Role!',
            text: res.data.message,
            icon: 'success',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
          this.$router.push({ name: 'PrivillageAdmin' })
        }).catch(err => {
          if (err.response.status == 422){
            this.validationErrors = Object.values(err.response.data.errors);
            this.$swal({
              toast: true,
              title: 'Failed Update Role!',
              text: this.validationErrors[0][0],
              icon: 'error',
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            })
          }
        })
      }
    }
  }
</script>
